import React, { useEffect, useMemo, useState } from 'react'
import { useIsVisible, VisibilityConfig } from '../../hooks'
import { DEFAULT_TRACKING_CONFIG } from './contants'
import { useLatest } from '../../utils'

interface TrackingContainerCallbacks {
  onVisibilityChange?: (isVisible: boolean) => void
  onVisible?: () => void
  onHidden?: () => void
  onFirstVisible?: () => void
}

interface TrackingContainerParameters extends Record<string, unknown> {
  config?: VisibilityConfig
  className?: string
}

export type TrackingContainerProps = React.PropsWithChildren<
  TrackingContainerCallbacks & TrackingContainerParameters
>

/**
 * Wrapper component that uses useIsVisible hook to watch
 * if the children element(s) are visible in the specified element/viewport.
 *
 * By default consider element being viewed
 * if at least 70% of the element is seen in the viewport (threshold: 0.7)
 * for at least 0.5 sec (duration: { visible: 500 } in ms)
 */
export const TrackingContainer = (props: TrackingContainerProps) => {
  const {
    onVisibilityChange,
    onVisible,
    onHidden,
    onFirstVisible,
    config: propsConfig,
    children,
    className,
    ...rest
  } = props

  const config = useMemo(
    () => ({ ...DEFAULT_TRACKING_CONFIG, ...propsConfig }),
    [propsConfig],
  )

  const { isVisible, ref } = useIsVisible<HTMLDivElement>(config)
  const [isFirstEventFired, setIsFirstEventFired] = useState(false)

  const callbacks = useLatest<TrackingContainerCallbacks>({
    onVisibilityChange,
    onVisible,
    onHidden,
    onFirstVisible,
  })

  useEffect(() => {
    callbacks.onVisibilityChange?.(isVisible)
    if (isVisible) {
      callbacks.onVisible?.()
    } else {
      callbacks.onHidden?.()
    }
  }, [isVisible])

  useEffect(() => {
    if (isVisible && !isFirstEventFired) {
      setIsFirstEventFired(true)
      callbacks.onFirstVisible?.()
    }
  }, [isVisible, isFirstEventFired, setIsFirstEventFired])

  return (
    <div
      {...rest}
      className={`tracking-container ${className || ''}`}
      ref={ref}
    >
      {children}
    </div>
  )
}
