import 'intersection-observer'

import { ObserverCallback, ObserverDisconnect } from './types'
import { getOrCreateObserverMetadata, deleteObserverMetadata } from './utils'

/**
 * @param element DOM Element to observe
 * @param callback Callback function to trigger when intersection status changes
 * @param config Intersection Observer config
 * @return Cleanup function that should be triggered to unregister the observer
 */
export const observe = (
  element: Element | undefined | null,
  callback: ObserverCallback,
  config: IntersectionObserverInit,
): ObserverDisconnect => {
  if (!element) {
    return () => {}
  }

  const { configId, observer, elementCallbacksMap } =
    getOrCreateObserverMetadata(config)

  if (!elementCallbacksMap.has(element)) {
    elementCallbacksMap.set(element, [])
  }

  const callbacks = elementCallbacksMap.get(element)!
  callbacks.push(callback)

  observer.observe(element)

  return function unobserve() {
    callbacks.splice(callbacks.indexOf(callback), 1)

    if (callbacks.length === 0) {
      elementCallbacksMap.delete(element)
      observer.unobserve(element)
    }

    if (elementCallbacksMap.size === 0) {
      observer.disconnect()
      deleteObserverMetadata(configId)
    }
  }
}
