import { ObserverCallback } from './types'

interface ObserverMetadata {
  observer: IntersectionObserver
  elementCallbacksMap: Map<Element, Array<ObserverCallback>>
}

const ElementIdMap: WeakMap<Element | Document, string> = new WeakMap()
const ConfigObserverMetadataMap = new Map<string, ObserverMetadata>()
let elementCounter = 0

/**
 * Generate a unique ID for the element
 * @param element
 * @returns id of the element to be used as part of IntersectionObserver config id
 */
const getOrCreateElementId = (
  element: IntersectionObserverInit['root'],
): string | undefined => {
  if (!element) return undefined

  if (ElementIdMap.has(element)) return ElementIdMap.get(element)

  ElementIdMap.set(element, elementCounter.toString())
  elementCounter++
  return ElementIdMap.get(element)
}

/**
 * Convert the config to a string Id, based on the values.
 * Ensures we can reuse the same observer when observing elements with the same config.
 * @param config IntersectionObserver config
 * @returns id of the configuration which is used as a key to find a previously created IntersectionObserver
 */
export const getConfigId = (config: IntersectionObserverInit) => {
  if (!config) return 'default'

  return Object.keys(config)
    .sort()
    .filter(
      (key) => config[key as keyof IntersectionObserverInit] !== undefined,
    )
    .map(
      (key) =>
        `${key}_${
          key === 'root'
            ? getOrCreateElementId(config.root)
            : config[key as keyof IntersectionObserverInit]
        }`,
    )
    .toString()
}

/**
 * Create a new IntersectionObserver for the provided config and a new map
 * for storing elements that need to be observed and corresponding callbacks.
 * @param config IntersectionObserver config
 * @returns observer metadata with the corresponding config id
 */
export const createObserverMetadata = (
  config: IntersectionObserverInit,
): ObserverMetadata => {
  const elementCallbacksMap = new Map<Element, Array<ObserverCallback>>()

  const observer = new window.IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      elementCallbacksMap.get(entry.target)?.forEach((callback) => {
        callback(entry)
      })
    })
  }, config)

  return { observer, elementCallbacksMap }
}

/**
 * Get previously used observer or create a new one if no observers have been created yet for this config
 * @param config - Intersection Observer config
 * @returns observer metadata with the corresponding config id
 */
export const getOrCreateObserverMetadata = (
  config: IntersectionObserverInit,
): ObserverMetadata & { configId: string } => {
  const configId = getConfigId(config)

  if (!ConfigObserverMetadataMap.has(configId)) {
    ConfigObserverMetadataMap.set(configId, createObserverMetadata(config))
  }

  const observerMetadata = ConfigObserverMetadataMap.get(configId)!
  return {
    ...observerMetadata,
    configId,
  }
}

/**
 * Find and delete observer and its metadata by provided configId
 * @param configId Intersection Observer configId
 */
export const deleteObserverMetadata = (configId: string) => {
  ConfigObserverMetadataMap.delete(configId)
}
