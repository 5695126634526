/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconLockDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M2.5 11.943c0-.614.532-1.111 1.188-1.111h16.625c.655 0 1.187.497 1.187 1.111v9.444c0 .614-.532 1.111-1.187 1.111H3.688c-.656 0-1.188-.497-1.188-1.11zm2.375 1.111v7.222h14.25v-7.222z'
          clipRule='evenodd'
        />
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12 4.722c-2.552 0-4.75 2.087-4.75 4.815v1.852H4.875V9.537C4.875 5.719 7.994 2.5 12 2.5s7.125 3.22 7.125 7.037v1.852H16.75V9.537c0-2.728-2.198-4.815-4.75-4.815'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconLockDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconLockDefault
