import { useEffect, useRef, useState } from 'react'
import { observe } from '../../observer'
import { useLatest } from '../../utils'

export type VisibilityConfig = Omit<IntersectionObserverInit, 'root'> & {
  duration?: {
    visible?: number
    hidden?: number
  }
}

const useIsVisibleCurrentState = <T extends HTMLElement, K extends HTMLElement>(
  config?: VisibilityConfig,
) => {
  const params = useLatest(config)
  const ref = useRef<T | null>(null)
  const rootRef = useRef<K | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const unobserve = observe(
      ref.current,
      (entry) => {
        setIsVisible(entry.isIntersecting)
      },
      { root: rootRef.current, ...params },
    )

    return () => {
      unobserve()
    }
  }, [])

  return { isVisible, ref, rootRef }
}

/**
 * React Hook for calculating if the provided element is visible/hidden in the viewport/other root element for the provided duration.
 *
 *  Example. Use to find out if the element has been fully visible in the viewport for 2 seconds:
 * `const { isVisible, ref } = useIsVisible({ threshold: 1, duration: { visible: 2000 }})`
 * `<div ref={ref}>Container to watch</div>`
 *
 * @param {VisibilityConfig} config
 * @returns Returns object:
 * - isVisible {boolean} `true` if any element intersects the root element, else `false`
 * - ref {RefObject} RefObject containing link to the HTMLElement that should be watched
 * - rootRef {RefObject} RefObject containing link to the root HTMLElement (default viewport)
 */
export const useIsVisible = <
  T extends HTMLElement = HTMLDivElement,
  K extends HTMLElement = HTMLDivElement,
>(
  config?: VisibilityConfig,
) => {
  const { duration, ...params } = useLatest(config || {})
  const { isVisible, ...rest } = useIsVisibleCurrentState<T, K>(params)
  const [isStillVisible, setIsStillVisible] = useState(false)

  useEffect(() => {
    let timeoutID: ReturnType<typeof setTimeout>
    const delay = isVisible ? duration?.visible : duration?.hidden

    if (delay) {
      timeoutID = setTimeout(() => setIsStillVisible(isVisible), delay)
    } else {
      setIsStillVisible(isVisible)
    }

    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID)
      }
    }
  }, [isVisible])

  return { isVisible: isStillVisible, ...rest }
}
