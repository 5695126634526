import { useEffect, useRef } from 'react'

export const useLatest = <T>(props: T) => {
  const ref = useRef<T>(props)

  useEffect(() => {
    ref.current = props
  })

  return ref.current
}
