import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { STATE_DEFAULT, STATE_ERROR, STATE_SUCCESS } from '../Forms/constants'
import { getState } from '../Forms/utils'
import { PROP_TYPE_CHILDREN } from '../constants'
import { IconWarningDefault } from '../Icons'
import i18nInstance from '../../i18n/i18nInstance'

export interface FormGroupProps extends React.HTMLProps<HTMLDivElement> {
  help?: string
  label?: string
  children: React.ReactNode | React.ReactNode[]
  childrenContainerClassName?: string
  maxlength?: number
  optional?: boolean
  required?: boolean
  success?: string | string[] | null
  error?: string | string[] | null
  touched?: boolean
  value?: string
}

export default class FormGroup extends PureComponent<FormGroupProps> {
  static propTypes = {
    children: PROP_TYPE_CHILDREN.isRequired,
    className: PropTypes.string,
    childrenContainerClassName: PropTypes.string,
    help: PropTypes.string,
    label: PropTypes.string,
    maxlength: PropTypes.number,
    name: PropTypes.string.isRequired,
    optional: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string.isRequired),
    ]),
    success: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string.isRequired),
    ]),
    touched: PropTypes.bool,
    value: PropTypes.string,
  }

  render() {
    const {
      children,
      className,
      childrenContainerClassName = '',
      error,
      help,
      label,
      maxlength,
      name,
      optional,
      required,
      success,
      touched,
      value,
      ...restProps
    } = this.props
    const state = getState({ error, success, touched })
    const classes = cn({
      'mc-form-group': true,
      [`mc-form-group--${state}`]: state,
      [className ?? '']: className,
    })

    const valueLength = value?.length || 0
    const maxlengthExceeded = valueLength > (maxlength ?? Infinity)
    const t = i18nInstance.getFixedT(null, '@mc/design-system')

    return (
      <div {...restProps} className={classes}>
        <div className='row no-gutters justify-content-between align-items-center'>
          <div className='col align-self-end'>
            {label && (
              <label
                htmlFor={name}
                className='d-block mc-text-h8 mc-text--left mc-mb-1'
              >
                {label}
                {required && <span aria-hidden='true'>*</span>}
              </label>
            )}
          </div>

          {optional && (
            <div className='col-auto align-self-end'>
              <p className='mc-text-x-small mc-opacity--silenced mc-text--right mc-mb-1'>
                {t('form.optional')}
              </p>
            </div>
          )}

          <div className='col-12'>
            <div className={childrenContainerClassName}>{children}</div>
          </div>

          <div className='col align-self-start'>
            {state === STATE_DEFAULT && (
              <p
                id={`${name}-help-text`}
                className='mc-text-x-small mc-opacity--muted mc-text--left mc-mt-1'
              >
                {help}
              </p>
            )}

            {state === STATE_ERROR && (
              <p
                id={`${name}-help-text`}
                className='mc-text-x-small mc-text--error mc-text--left mc-mt-1'
                aria-live='assertive'
              >
                <IconWarningDefault className='mc-icon--scale-4' />
                {error}
              </p>
            )}

            {state === STATE_SUCCESS && (
              <p
                id={`${name}-help-text`}
                className='mc-text-x-small mc-text--success mc-text--left mc-mt-1'
              >
                {success}
              </p>
            )}
          </div>

          <div className='col-auto align-self-start mc-ml-auto'>
            {maxlength && (
              <p
                data-testid='maxlength-indicator'
                className={cn('mc-text-x-small mc-text--right mc-mt-1', {
                  'mc-opacity--muted ': !maxlengthExceeded,
                  'mc-text--error': maxlengthExceeded,
                })}
                aria-live='polite'
                aria-atomic='true'
              >
                <span id={`${name}-info-text`} className='mc-sr-only'>
                  {t('form.charactersRemainingLabel', {
                    valueLength,
                    maxlength,
                  })}
                </span>
                <span aria-hidden>
                  {t('form.charactersRemainingNumber', {
                    valueLength,
                    maxlength,
                  })}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
}
