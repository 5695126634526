import React, { useContext } from 'react'
import { string } from 'prop-types'

import { useTranslation, withI18nContext } from '@mc/i18n'
import i18nInstance from '../../i18n/i18nInstance'
import { ModalContext } from '../Modal'
import Button, { ButtonProps } from '../Button'
import { IconCloseDefault } from '../Icons'

const ModalClose = ({
  className = '',
  ...props
}: React.PropsWithChildren<ButtonProps>) => {
  const { close } = useContext(ModalContext)
  const { t } = useTranslation('@mc/design-system')

  return (
    <Button
      {...props}
      className={`mc-modal__close mc-p-0 ${className}`}
      data-testid='modal-close-button'
      kind='link'
      onClick={close('close')}
    >
      <IconCloseDefault
        className='mc-icon--scale-4 mc-m-2'
        srText={t('modal.closeButtonAlt')}
      />
    </Button>
  )
}

ModalClose.propTypes = {
  className: string,
}

export default withI18nContext(i18nInstance)(ModalClose)
